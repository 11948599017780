import { FC, useState, useEffect } from 'react';
import Link from 'next/link';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IWidgetsSlider, IWidgetsPopularBook, IWidgetsPopularGenre } from '@/interfaces/widgets.interface';
import { DEV_ABSOLUTE_URL } from '@/helpers/variables';

interface ISidebarLeft {
  isSmallPage?: boolean;
  slider: IWidgetsSlider[] | undefined;
  popularBook: IWidgetsPopularBook[] | undefined;
  popularGenres: IWidgetsPopularGenre[] | undefined;
}

const SidebarLeft: FC<ISidebarLeft> = ({ isSmallPage, slider, popularBook, popularGenres }) => {
  //Рандомная книга для популярных книг
  const [randomPopularBookNumber, setRandomPopularBookNumber] = useState(0);

  useEffect(() => {
    if (Number(popularBook?.length) > 0) {
      setRandomPopularBookNumber(Math.floor(Math.random() * Number(popularBook?.length) + 1));
    }
  }, []);

  const randomPopularBook = Number(popularBook?.length) > 0 ? popularBook![randomPopularBookNumber] : ([] as any);

  //slick settings
  const settings = {
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToScroll: 1,
    slidesToShow: 1,
  };

  return (
    <>
      {slider!?.length > 0 && (
        <div className="sideBlock sideBlock_slider">
          <div className="sideBlock__title">
            <span className="sideBlock__titleTxt">Новинки</span>
            <Link prefetch={false} className="sideBlock__titleLink" href="/">
              Все новинки
            </Link>
          </div>
          <div className="sideBlock__content sideBlock__content_slider" id="__newsSlider">
            <Slider {...settings} className="sideBlock__slider">
              <Link prefetch={false} href={`/redirect_to_self_pub`} className="sideBlock__slide">
                <img className="sideBlock__img" src={'/banners/selfpub-card.jpg'} alt="img" />
              </Link>
              {slider?.map((item) => (
                <Link prefetch={false} href={`/authors/${item.author_alias}/${item.book_alias}`} className="sideBlock__slide" key={item.external_id}>
                  <img className="sideBlock__img" src={`${DEV_ABSOLUTE_URL}/covers/${item.external_id}.jpg`} alt="img" />
                </Link>
              ))}
            </Slider>
          </div>
          <div className="sideBlock__sliderControls"></div>
        </div>
      )}

      {!isSmallPage && popularBook!?.length > 0 && (
        <>
          <div className="sideBlock">
            <div className="sideBlock__title">
              <span className="sideBlock__titleTxt">Популярные книги</span>
            </div>
            <div className="sideBlock__content sideBlock__content_card">
              <Link prefetch={false} className="sideBlock__imgWrap" href={`/authors/${randomPopularBook?.author_alias}/${randomPopularBook?.book_alias}`}>
                <img className="sideBlock__imgBook" src={`${DEV_ABSOLUTE_URL}/covers/${randomPopularBook?.external_id}.jpg`} alt="img" />
              </Link>
              <div className="sideBlock__contentDesc">
                <Link prefetch={false} className="sideBlock__heading" href={`/authors/${randomPopularBook?.author_alias}/${randomPopularBook?.book_alias}`}>
                  {randomPopularBook?.book_title}
                </Link>
                <Link prefetch={false} className="sideBlock__author" href={`/authors/${randomPopularBook?.author_alias}`}>
                  {randomPopularBook?.author_firstName} {randomPopularBook?.author_lastName}
                </Link>
                <Link prefetch={false} className="sideBlock__link" href={`/genres/${randomPopularBook?.genres[0].alias}`}>
                  {randomPopularBook?.genres[0].title}
                </Link>
              </div>
            </div>
          </div>
          <div className="sideBlock">
            <div className="sideBlock__title">
              <span className="sideBlock__titleTxt">Рейтинги книг</span>
              <Link prefetch={false} className="sideBlock__titleLink" href="/tops">
                Все рейтинги
              </Link>
            </div>
            <div className="sideBlock__content sideBlock__content_tops">
              <Link prefetch={false} className="sideBlock__link" href="/top100_books">
                Топ 100 книг
              </Link>
              <Link prefetch={false} className="sideBlock__link" href="/top100_authors">
                Топ 100 авторов
              </Link>
            </div>
          </div>

          {popularGenres && popularGenres.length > 0 && (
            <div className="sideBlock">
              <div className="sideBlock__title">
                <span className="sideBlock__titleTxt">Жанры</span>
                <Link prefetch={false} className="sideBlock__titleLink" href="/genres">
                  Все жанры
                </Link>
              </div>
              <div className="sideBlock__content">
                {popularGenres.map((item, i) => (
                  <Link prefetch={false} className="sideBlock__link" key={`${item.alias}-${i}`} href={`/genres/${item.alias}`}>
                    {item.title}
                  </Link>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default SidebarLeft;
