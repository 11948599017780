import { FC, useState } from 'react';
import { useRouter } from 'next/router';
import { useGetUrlPath } from '@/hooks/useGetUrlPath';
import Link from 'next/link';
import styles from './Header.module.scss';

const Header: FC = () => {
  const [isMobileMenu, setIsMobileMenu] = useState(false);

  const router = useRouter();
  const [inputSearch, setInputSearch] = useState('');

  const setMenuModeHandle = () => {
    setIsMobileMenu(!isMobileMenu);
  };

  const getSearchWord = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearch(event.target.value);
  };

  const searchByPressEnter = (event: React.KeyboardEvent<object>) => {
    if (String(event.code) == 'Enter') {
      router.push(`/search/${searchWord}`);
    }
  };

  const isMainPage = useGetUrlPath().length === 1;

  const searchWord = inputSearch.includes(' ') ? inputSearch.split(' ').join('+') : inputSearch;

  return (
    <div className={styles.header} menu-data={isMobileMenu ? 'mobile-menu' : 'desktop-menu'}>
      <Link prefetch={false} href="/" className={styles.logo}>
        {isMainPage ? <h1 className={styles.logo__title}>Электронная библиотека bookz.ru</h1> : <span className={styles.logo__title}>Электронная библиотека bookz.ru</span>}
        <span className={styles.logo__subTitle}> скачивайте книги тут</span>
      </Link>
      <div className={styles.menu}>
        <div className={styles.menu__btn} onClick={setMenuModeHandle}>
          <div className={styles.burger}>
            <span className={styles.burger__topper}></span>
            <span className={styles.burger__bottom}></span>
            <span className={styles.burger__footer}></span>
          </div>
          <div className={styles.menu__txt}>Меню</div>
        </div>

        <ul className={`${styles.menuList} ${styles.menuList_mobToggle}`}>
          <li className={styles.menuList__item}>
            <Link prefetch={false} className={styles.menuList__itemLink} href="/new_books">
              Новинки книг
            </Link>
          </li>
          <li className={styles.menuList__item}>
            <Link prefetch={false} className={styles.menuList__itemLink} href="/new_audioknigi">
              Аудиокниги
            </Link>
          </li>
          <li className={styles.menuList__item}>
            <Link prefetch={false} className={styles.menuList__itemLink} href="/comments">
              Новые отзывы
            </Link>
          </li>
          <li className={styles.menuList__item}>
            <Link prefetch={false} className={styles.menuList__itemLink} href="/authors">
              Авторы
            </Link>
          </li>
          <li className={styles.menuList__item}>
            <Link prefetch={false} className={styles.menuList__itemLink} href="/serii-knig">
              Серии
            </Link>
          </li>
          <li className={styles.menuList__item}>
            <Link prefetch={false} className={styles.menuList__itemLink} href="/genres">
              Жанры
            </Link>
          </li>
          <li className={styles.menuList__item}>
            <Link prefetch={false} className={styles.menuList__itemLink} href="/feedback">
              Обратная связь
            </Link>
          </li>
        </ul>
      </div>

      <div className={styles.search}>
        <input onChange={getSearchWord} onKeyDown={searchByPressEnter} className={styles.search__input} type="text" placeholder="Поиск..." />
        {!!inputSearch.length ? (
          <Link prefetch={false} className={styles.search__button} href={`/search/${searchWord}`}>
            Найти
          </Link>
        ) : (
          <div className={styles.search__button}>Найти</div>
        )}
      </div>
    </div>
  );
};

export default Header;
