import Link from 'next/link';
import { getCurrentYear } from '@/helpers/helpers';
import styles from './Footer.module.scss';

const Footer = () => {
  const currentYear = getCurrentYear();

  return (
    <div className={styles.footer}>
      <div className={styles.footer__content}>
        <div className={styles.footer__menu}>
          <div className={styles.footer__menuPart}>
            <div className={styles.footer__title}>Разное</div>
            <ul className={styles.footer__subMenu}>
              <li className={styles.footer__subItem}>
                <Link prefetch={false} className={styles.footer__subLink} href="/feedback">
                  Обратная связь
                </Link>
              </li>
              <li className={styles.footer__subItem}>
                <Link prefetch={false} className={styles.footer__subLink} href={'/faq'}>
                  FAQ по библиотеке
                </Link>
              </li>
              <li className={styles.footer__subItem}>
                <Link prefetch={false} className={styles.footer__subLink} target="_blank" href="/redirect_to_self_pub_authors/">
                  Стать автором
                </Link>
              </li>
              {/* <li className={styles.footer__subItem}>
                <Link prefetch={false} className={styles.footer__subLink} href={'/'}>
                  Вебмастеру
                </Link>
              </li> */}
            </ul>
          </div>

          <div className={styles.footer__menuPart}>
            <div className={styles.footer__title}>Партнеры</div>
            <ul className={styles.footer__subMenu}>
              <li className={styles.footer__subItem}>
                <Link prefetch={false} target="_blank" className={styles.footer__subLink} href={'/redirect_to_litres'}>
                  Литрес
                </Link>
              </li>
              <li className={styles.footer__subItem}>
                <Link prefetch={false} target="_blank" className={styles.footer__subLink} href={'/redirect_to_self_pub'}>
                  Литрес Самиздат
                </Link>
              </li>
              <li className={styles.footer__subItem}>
                <Link target="_blank" className={styles.footer__subLink} href={'/redirect_to_my_book'}>
                  MyBook - книги онлайн
                </Link>
              </li>
            </ul>
          </div>

          <div className={styles.footer__menuPart}>
            <div className={styles.footer__title}>Рассылки</div>
            <ul className={styles.footer__subMenu}>
              <li className={styles.footer__subItem}>
                <Link prefetch={false} className={styles.footer__subLink} href={'/new_books'}>
                  Новинки книг
                </Link>
              </li>
              <li className={styles.footer__subItem}>
                <Link prefetch={false} className={styles.footer__subLink} href={'/rss'}>
                  Что такое RSS?
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className={styles.footer__bottom}>
          <div className={styles.footer__mail}>
            По всем вопросам пишите нам на почту:{' '}
            <a className={styles.footer__mailLink} href="mailto:support@bookz.ru">
              support@bookz.ru
            </a>
          </div>
          <div className={styles.footer__sign}>{`2003-${currentYear} bookZ.ru`}</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
