import { FC, useEffect, useState } from 'react';
import Link from 'next/link';
import { IWidgetsToday, IWidgetsLastReview, IWidgetsTrendBook, IPopularSearch } from '@/interfaces/widgets.interface';
import { DEV_ABSOLUTE_URL } from '@/helpers/variables';
import { getTruncatedTxt } from '@/helpers/helpers';

interface ISidebarRight {
  isSmallPage?: boolean;
  today: IWidgetsToday | undefined;
  lastReviews: IWidgetsLastReview[] | undefined;
  popularSearch: IPopularSearch[] | undefined;
  trendBook: IWidgetsTrendBook | undefined;
}

const SidebarRight: FC<ISidebarRight> = ({ isSmallPage, today, lastReviews, popularSearch, trendBook }) => {
  const createMarkup = (c: string) => {
    return { __html: c };
  };

  const todayAuthorAlias = today?.description?.split('/authors/')[1];

  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <>
      {today && today.description.length > 0 && isClient && (
        <div className="sideBlock">
          <div className="sideBlock__title">
            <span className="sideBlock__titleTxt">В этот день ...</span>
          </div>
          <div className="sideBlock__content sideBlock__content_author">
            <Link className="sideBlock__img sideBlock__img_author" prefetch={false} href={`/authors/${todayAuthorAlias}`}>
              <img src={`${DEV_ABSOLUTE_URL}/thisday/${today?.img}`} alt="img" />
            </Link>
            <div className="sideBlock__contentDesc">
              <Link prefetch={false} href={`/authors/${todayAuthorAlias}`}>
                <span className="sideBlock__txt">
                  {today.event_day} {today.event}
                </span>
                <span className="sideBlock__txt" dangerouslySetInnerHTML={createMarkup(today!?.description)}></span>
              </Link>
            </div>
          </div>
        </div>
      )}
      {!isSmallPage && (
        <>
          {lastReviews && lastReviews.length > 0 && (
            <div className="sideBlock">
              <div className="sideBlock__title">
                <span className="sideBlock__titleTxt">Новый отзыв</span>
              </div>
              <div className="sideBlock__content sideBlock__content_card">
                <Link prefetch={false} className="sideBlock__imgWrap" href={`/authors/${lastReviews[0].author_alias}/${lastReviews[0].book_alias}`}>
                  <img className="sideBlock__imgBook" src={`${DEV_ABSOLUTE_URL}/covers/${lastReviews![0].external_id}.jpg`} alt="img" />
                </Link>
                <div className="sideBlock__contentDesc">
                  <Link prefetch={false} className="sideBlock__heading" href={`/authors/${lastReviews![0].author_alias}/${lastReviews![0].book_alias}`}>
                    {lastReviews![0].book_title}
                  </Link>
                  <div className="sideBlock__author sideBlock__author_noLink">{lastReviews![0].name}:</div>
                  <div className="sideBlock__txt">{getTruncatedTxt(lastReviews![0].message, 100)}</div>
                </div>
              </div>
            </div>
          )}

          {popularSearch!?.length > 0 && (
            <div className="sideBlock">
              <div className="sideBlock__title">
                <span className="sideBlock__titleTxt">У нас ищут</span>
              </div>
              <div className="sideBlock__content">
                {popularSearch?.slice(0, 10).map((item, index) => (
                  <Link prefetch={false} className="sideBlock__link" key={`${item.alias}-${index}`} href={`/authors/${item.alias}`}>
                    {item.first_name} {item.last_name} (автор)
                  </Link>
                ))}
              </div>
            </div>
          )}

          {trendBook && trendBook?.book_alias.length > 0 && (
            <div className="sideBlock">
              <div className="sideBlock__title">
                <span className="sideBlock__titleTxt">В тренде</span>
              </div>
              <div className="sideBlock__content sideBlock__content_card">
                <Link prefetch={false} className="sideBlock__imgWrap" href={`/authors/${trendBook.author_alias}/${trendBook.book_alias}`}>
                  <img className="sideBlock__imgBook" src={`${DEV_ABSOLUTE_URL}/covers/${trendBook.external_id}.jpg`} alt="img" />
                </Link>

                <div className="sideBlock__contentDesc">
                  <Link prefetch={false} className="sideBlock__heading" href={`/authors/${trendBook.author_alias}/${trendBook.book_alias}`}>
                    {trendBook.book_title}
                  </Link>
                  <Link prefetch={false} href={`/authors/${trendBook.author_alias}`} className="sideBlock__author">{`${trendBook.first_name} ${trendBook.last_name}`}</Link>
                  <div className="sideBlock__txt">{getTruncatedTxt(trendBook.book_description, 100)}</div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default SidebarRight;
